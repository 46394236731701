<template>
  <div>
    <h2>Política de privacidad y aviso legal</h2>
    <p>TBC.—</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Política de privacidad y aviso legal',
  },
}
</script>

<style scoped>

</style>
