import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';
import * as firebase from 'firebase/app';
import routes from './routes';

const router = new VueRouter({
  mode: 'history',
  routes
});

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueAnalytics, {
  id: 'UA-143008928-1',
  router,
});

firebase.initializeApp({
  apiKey: "AIzaSyAAcS8l-zqBFhaTq10XVxYB7KDAUA4hawQ",
  authDomain: "gestoria-knk.firebaseapp.com",
  databaseURL: "https://gestoria-knk.firebaseio.com",
  projectId: "gestoria-knk",
  storageBucket: "gestoria-knk.appspot.com",
  messagingSenderId: "820203338444",
  appId: "1:820203338444:web:1a4d779b8be2e68d"
});

window.initMap = () => {};
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
