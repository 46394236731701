<template>
  <div>
    <div class="container inner">
      <div class="contact-details">
        <h2>Teléfono</h2>
        <p><a href="tel:+34951476668">951 476 668</a> / <a href="https://wa.me/+34722435896">722 435 896</a></p>
        <h2>Email</h2>
        <p><a href="mailto:info@gestoriaknk.es">info@gestoriaknk.es</a></p>
        <hr>
        <form-llamada details="Contact page">
          Si prefieres que te llamemos...
        </form-llamada>
      </div>
      <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.776599225678!2d-4.7079163890534454!3d36.82387289589891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72eb8c80a0bff3%3A0x957fea76a01565df!2sGestor%C3%ADa+KNK+Gestor%C3%ADa+Administrativa+Moreno+Moraleda!5e0!3m2!1sen!2sus!4v1561668462655!5m2!1sen!2sus" frameborder="0" style="border:0; height: 460px; width: 100%;" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import FormLlamada from '../components/FormLlamada.vue';

export default {
  components: {
    FormLlamada,
  },
  metaInfo: {
    title: 'Contacto',
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}
.contact-details {
  width: 40%;
}
.map {
  width: 100%;
  max-width: 440px;
  height: 460px;
}
h2 {
  margin: 20px auto 3px;
}
.container hr {
  border: 0px;
  height: 1px;
  margin: 30px auto 5px;
  border-top: 1px solid #f2f2f2;
}
@media screen and (max-width: 860px){
  .contact-details {
    width: 100%;
  }
  .container {
    flex-direction: column;
    text-align: center;
  }
  .map {
    margin-top: 60px;
  }
  .container.inner {
    padding: 0px;
  }
}
</style>
