<template>
  <div>
    <p><slot>Si necesitas más información, podemos llamarte:</slot></p>
    <form @submit.prevent="submitPhoneNumber">
      <small class="error" v-if="phoneInvalid">Introduce un teléfono / email correcto</small>
      <app-input 
        type="text" 
        :invalid="phoneInvalid" 
        placeholder="Número de teléfono" 
        v-model="phoneNumber"
        @input="phoneInvalid = false"
        autocomplete="tel"
      ></app-input>
      <small class="error" v-if="privacyPolicyError">Debes aceptar nuestra politica de privacidad</small>
      <div class="opt-in">
        <input type="checkbox" id="opt-in" v-model="privacyPolicyAccepted">
        <label for="opt-in">Acepto la <router-link to="/aviso-legal">politica de privacidad y el aviso legal</router-link>.</label>
      </div>
      <div class="gdpr-notice">
        Al rellenar este formulario estás dando tu consentimiento expreso para el tratamiento de tus datos. La finalidad de la información recabada es poder ponernos en contacto contigo para proporcionarte información de nuestros servicios. Puedes ejercitar tu derecho al acceso, cancelación y rectificación de tus datos en cualquier momento enviándonos un email a <a href="mailto:info@gestoriaknk.es">info@gestoriaknk.es</a>.
      </div>
      <app-button class="app-button" type="submit">SOLICITA LLAMADA GRATUITA</app-button>
    </form>
    <sweet-modal icon="success" ref="successModal">
      ¡Recibido! Nos pondremos en contacto contigo.
    </sweet-modal>
  </div>
</template>

<script>
import AppButton from '../components/ui/AppButton.vue';
import AppInput from '../components/ui/AppInput.vue';
import { SweetModal } from 'sweet-modal-vue';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  props: ['details'],
  components: {
    AppButton,
    AppInput,
    SweetModal,
  },
  data() {
    return {
      phoneNumber: '',
      phoneInvalid: false,
      privacyPolicyError: false,
      privacyPolicyAccepted: false,
    }
  },
  watch: {
    privacyPolicyAccepted(value) {
      if (this.privacyPolicyError && value) this.privacyPolicyError = false;
    }
  },
  methods: {
    submitPhoneNumber: function() {
      let errors = false;
      if (this.phoneNumber.length < 5) {
        this.phoneInvalid = true;
        errors = true;
      }
      if (!this.privacyPolicyAccepted) {
        this.privacyPolicyError = true;
        errors = true;
      }
      if (errors) return;
      const optIn = this.privacyPolicyAccepted;
      const details = this.details;
      const phone = this.phoneNumber;
      const contacted = false;
      firebase.firestore().collection('clientes').doc(phone).set({
        optIn,
        details,
        phone,
        contacted
      });
      this.$ga.event('Express interest', 'filled form', `${this.details}`);
      this.phoneNumber = '';
      this.privacyPolicyAccepted = false;
      this.$refs.successModal.open();
    },
  },
}
</script>

<style scoped>
.opt-in {
  margin: 10px auto 10px;
  font-size: 13px;
}
.gdpr-notice {
  margin: 10px auto 25px;
  max-width: 500px;
  font-size: 11px;
}
small.error {
  display: block;
  color: var(--app-red);
}
@media screen and (max-width: 860px){
  .app-button {
    margin: 0px auto;
  }
}
</style>
