<template>
  <div class="TramiteView">
    <h1>{{ tramite.title }}</h1>
    <p>{{ tramite.description }}</p>
    <div class="bottom-container">
      <form-llamada :details="tramite.slug">
        Dejanos tu teléfono para más información
      </form-llamada>
    </div>
  </div>
</template>

<script>
import { tramites } from '../core';
import FormLlamada from '../components/FormLlamada.vue';

export default {
  components: {
    FormLlamada,
  },
  metaInfo() {
    const description = `Le asesoramos en la ${this.tramite.description.toLowerCase()}. `
    + `${this.tramite.title}.`;
    return {
      title: `Trámite: ${this.tramite.title}`,
      meta: [
        { name: 'description', content: description },
        { name: 'robots', content: 'index, follow' },
        { name: 'og:type', content: 'article' },
        { name: 'og:title', content: `Gestoría KNK: ${this.tramite.title}` },
        { name: 'og:description', content: description },
        { name: 'og:url', content: `https://www.gestoriaknk.es/tramites/${this.slug}` },
        { name: 'og:image', content: 'https://www.gestoriaknk.es/favicon.png' },
        { name: 'og:site_name', content: 'Gestoria KNK' },
      ]
    };
  },
  data() {
    return {
      tramite: {},
      slug: '',
    }
  },
  created() {
    this.slug = this.$route.params.slug;
    this.tramite = tramites.find((tramite) => tramite.slug === this.slug);
  },
  beforeRouteEnter (to, from, next) {
    const slug = to.params.slug;
    const tramite = tramites.find((tramite) => tramite.slug === slug);
    if (tramite) {
      next();
    } else {
      next('/');
    }
  },
}
</script>

<style>
p {
  font-size: 18px;
}
.bottom-container {
  margin-top: 60px;
  padding-top: 7vh;
  text-align: center;
}
.bottom-container button {
  margin: 0 auto !important;
}
@media screen and (max-width: 450px){
  .TramiteView {
    text-align: center;
  }
  .bottom-container {
    margin-top: 45px;
  }
}
</style>
