export const toSlug = function(str, separator = '-') {
  str = str.trim();
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  return str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-") // collapse dashes
      .replace(/^-+/, "") // trim - from start of text
      .replace(/-+$/, "") // trim - from end of text
      .replace(/-/g, separator);
}

export const applicationData = {
  particulares: {
    0: {
      name: 'Más Populares',
      processes: [
        {
          title: 'Declaración de la renta',
          description: 'Confección y presentación de la declaración de la renta',
        },
        {
          title: 'Escrituras',
          description: 'Inscripción de escrituras en el Registro de la Propiedad',
        },
        {
          title: 'Sucesiones',
          description: 'Tramitación de herencia, IRPF, impuesto de sucesiones...',
        },
        {
          title: 'Inscripción de parejas de hecho',
          description: 'Inscripción de parejas en el registro de uniones de hecho',
        },
        {
          title: 'Certificados catastrales',
          description: 'Consulta y certificación de datos del Catastro de cualquier localidad',
        },
      ]
    },
    1: {
      name: 'Vehículos',
      processes: [
        {
          title: 'Matriculaciones',
          description: 'Tramitación de la matriculación para la puesta en circulación de vehículos',
        },
        {
          title: 'Importación de vehículos',
          description: 'Trámites de matriculacion e importación de vehículos del extranjero',
        },
        {
          title: 'Transferencias de vehículos',
          description: 'Cambio de titularidad de vehículos entre dos personas',
        },
        {
          title: 'Bajas de vehículos',
          description: 'Gestiones de baja en Jefatura de vehículos por exportación o...',
        },
        {
          title: 'Rehabilitación de vehículos',
          description: 'Trámites de obtención del permiso de circulación para vehículos de baja',
        },
      ]
    },
    2: {
      name: 'Para Extranjeros',
      processes: [
        {
          title: 'Solicitud de Residencia',
          description: 'Personas que han vivido en territorio nacional durante cinco años',
        },
      ]
    }
  },
  empresas: {
    0: {
      name: 'Más Populares',
      processes: [
        {
          title: 'Asesoramiento fiscal',
          description: 'Planificación de las necesidades fiscales de su compañía.',
        },
        {
          title: 'Asesoramiento contable',
          description: 'Puesta al día de contabilidad para reducir carga fiscal',
        },
        {
          title: 'Impuestos',
          description: 'Confeccion, gestión y presentación de impuestos',
        },
      ]
    },
    1: {
      name: 'Laboral',
      processes: [
        {
          title: 'Alta / baja para Autónomos',
          description: 'Planificación de las necesidades fiscales de su compañía.',
        },
        {
          title: 'Afiliación del personal',
          description: 'Puesta al día de contabilidad para reducir carga fiscal',
        },
        {
          title: 'Nóminas',
          description: 'Administración del salario, seguridad social y obligaciones fiscales',
        },
        {
          title: 'Gestión de Jubilaciones',
          description: 'Administración del salario, seguridad social y obligaciones fiscales',
        },
        {
          title: 'Despidos y Conflictos Laborales',
          description: 'Gestiones relacionadas con despidos y Conflictos Laborales',
        },
        {
          title: 'Inspecciones de trabajo',
          description: 'Administración del salario, seguridad social y obligaciones fiscales',
        },
      ]
    },
    2: {
      name: 'Mercantil',
      processes: [
        {
          title: 'Constitución de Sociedades',
          description: 'Redacción de estatutos, alta de actividad fiscal',
        },
      ]
    },
  }
};

let processes = [];
for(let key in applicationData.particulares){
  processes.push(...applicationData.particulares[key].processes);
}
for(let key in applicationData.empresas){
  processes.push(...applicationData.empresas[key].processes);
}

export const tramites = processes.map(process => ({...process, slug: toSlug(process.title)}))
