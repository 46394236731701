import ParticularesView from './views/ParticularesView.vue';
import EmpresasView from './views/EmpresasView.vue';
import ContactoView from './views/ContactoView.vue';
import TramiteView from './views/TramiteView.vue';
import AvisoLegal from './views/AvisoLegal.vue';


const routes = [
  {
    path: '/',
    name: 'Particulares',
    component: ParticularesView
  },
  {
    path: '/empresas',
    name: 'Empresas',
    component: EmpresasView
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: ContactoView
  },
  {
    path: '/tramite/:slug',
    name: 'Tramite',
    component: TramiteView
  },
  {
    path: '/aviso-legal',
    name: 'AvisoLegal',
    component: AvisoLegal
  },
  {
    path: '*',
    redirect: '/',
  },
];

export default routes;
