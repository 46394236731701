<template>
  <input
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
  >
</template>

<script>
export default {
  props: ['value'],
}
</script>

<style scoped>
input{
  width: 267px;
  box-sizing: border-box;
  height: 44px;
  margin: 13px auto;
  padding: 0px 9px;
  font-size: 16px;
  background-color: #fff;
  border: thin solid #c4c4c4;
  box-shadow: inset 0 0 0 0 #fff;
  border-radius: 3px;
}
input[invalid] {
  border-color: var(--app-red);
}
</style>
