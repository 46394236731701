<template>
  <div>
    <template v-for="sKey in Object.keys(services)">
      <h2 :key="`h${sKey}`">{{ services[sKey].name }}</h2>
      <section :key="`s${sKey}`" class="services">
        <service-item v-for="process in services[sKey].processes" :key="process.name">
          <router-link :to='`/tramite/${ toSlug(process.title) }`'>{{ process.title }}</router-link>
          <p>{{ process.description }}</p>
        </service-item>
      </section>
    </template>
  </div>
</template>

<script>
import ServiceItem from '../components/services/ServiceItem.vue';
import { applicationData, toSlug } from '../core';
export default {
  metaInfo: {
    title: 'Servicios para Empresas',
  },
  components: {
    ServiceItem
  },
  data() {
    return {
      services: applicationData.empresas,
    }
  },
  methods: {
    toSlug,
  }
}
</script>

<style scoped>
.services {
  display: flex;
  flex-wrap: wrap;
}
</style>
